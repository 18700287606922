.content {
  position: relative;
  padding: 24px;
  border-radius: var(--common-border-radius);
  background-color: var(--colors-interface-form);
  backdrop-filter: blur(132px);
}

.btn {
  position: absolute;
  top: 24px;
  right: 37px;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: var(--common-transition);
  z-index: 1;
}

.btn:hover {
  opacity: .7;
}

.img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .btn {
    right: 24px;
  }
}
