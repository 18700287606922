.content {
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
}

.main_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_box {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: auto 0;
  width: 20px;
  height: 15px;
  transition: var(--common-transition);
}

.btn:hover {
  opacity: .7;
}

.btn_cross {
  width: 15px;
  height: 15px;
}

.img {
  width: 100%;
  height: 100%;
}

.done_img {
  width: 20px;
  height: 18px;
}

.details_box {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  text-align: left;
}

.goods_name {
  max-width: 268px;
}

.amount_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.sub_row {
  display: flex;
  gap: 32px;
}

@media screen and (max-width: 950px) {
  .sub_row {
    gap: 24px;
  }
}

@media screen and (max-width: 600px) {
  .info_box {
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {
  .main_row {
    flex-direction: column;
    align-items: flex-start;
  }

  .sub_row {
    align-self: flex-end;
    gap: 32px;
  }

  .details_box {
    padding-right: 0;
    gap: 10px;
  }

  .amount_box {
    min-width: 60px;
  }
}
