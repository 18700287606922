.form {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.title {
  align-self: center;
}

.policy_box {
  margin-bottom: 70px;
  text-align: left;
}

.policy_row {
  display: flex;
  align-items: flex-start;
}

.checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.policy {
  cursor: pointer;
  text-align: left;
}

.link {
  text-decoration: none;
}

.btn {
  align-self: center;
}

.reactivate_btn {
  align-self: center;
  padding: 0;
  background: none;
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.login_box {
  align-self: center;
  margin-top: 10px;
}

.login_btn {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: var(--common-transition);
}

.login_btn:hover {
  opacity: .7;
}

.recaptcha {
  margin: 0 auto 20px;
}

.reactivate_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
