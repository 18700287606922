.swiper-button-next,
.swiper-button-prev {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: var(--colors-slider-buttons);
  right: 56px;
  transition: var(--common-transition);
}

.swiper-button-prev {
  left: 56px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 0.7;
}

.swiper-button-next::after {
  content: url(../src/images/icons/arrow-right.svg);
  font-size: 30px;
}

.swiper-button-prev::after {
  content: url(../src/images/icons/arrow-left.svg);
  font-size: 30px;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  opacity: 1;
  background: rgba(145, 142, 134, 0.4);
}

.swiper-pagination-bullet-active {
  background: var(--colors-slider-buttons);
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
}

.rc-slider-rail {
  background-color: #fff;
}

.rc-slider-track {
  background-color: var(--text-select-color);
}

.rc-slider-handle {
  border: 2px solid #2f1b10;
  background-color: #fff;
}

.PhoneInputCountry {
  display: none;
}

.PhoneInputInput {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--colors-border-default);

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.PhoneInputInput:focus {
  border: 1px solid var(--colors-checkbox-border);
}

/* .PhoneInputInput:valid {
  border: 1px solid var(--colors-border-correct);
} */

.PhoneInputInput:invalid {
  border: 1px solid var(--text-stock-color);
}

.PhoneInputInput:disabled {
  border: 1px solid var(--colors-checkbox-border);
}

@media screen and (max-width: 750px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .swiper .swiper-pagination {
    bottom: 0;
  }
}
