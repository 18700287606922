.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--text-black-color);
}

.logo {
  width: 771px;
}

.question_box {
  display: flex;
  flex-direction: column;
}

.btn_box {
  display: flex;
}

.btn {
  width: 162px;
  height: 56px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.alarm {
  max-width: 600px;
}

@media screen and (max-width: 800px) {
  .logo {
    width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 340px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    width: 300px;
  }

  .btn {
    width: 140px;
  }

  .btn:first-child {
    margin-right: 20px;
  }
}
