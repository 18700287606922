.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1160px;
  margin: 0 auto;
  text-align: left;
}

.title {
  max-width: 711px;
}

.main_btn {
  width: 300px;
  height: 72px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.section_btn {
  align-self: center;
  color: var(--text-black-color);
}

.mission_text {
  max-width: 729px;
}

.clarification_box {
  display: flex;
  gap: 74px;
}

.clarificationEl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.clarification_text {
  margin-top: -20px;
}

.beer_img {
  position: absolute;
  top: 0;
  right: -350px;
  width: 804px;
  height: 924px;
  z-index: -1;
}

.slider_box {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.slide {
  width: 100%;
  height: 456px;
  border-radius: var(--common-border-radius);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
}

.slide_cover {
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: var(--common-border-radius);
}

.slider_text {
  max-width: 462px;
  margin: auto;
  margin-left: 164px;
}

.novalty {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.novalty_goods_box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 260px));
  gap: 60px 40px;
  justify-content: center;
}

.map {
  border-radius: var(--common-border-radius);
  width: 560px;
  height: 397px;
}

.map_box {
  width: 100%;
}

.map_items_box {
  display: flex;
  gap: 40px;
}

.shops_info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}

.shops_info_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.promo_items_box {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.mob_promo_items_box {
  display: none;
}

.collab_items_box {
  display: flex;
  gap: 40px;
  margin-bottom: 48px;
}

.mob_collab_items_box {
  display: none;
}

.collab_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.promo_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.collab_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.collab_text {
  max-width: 348px;
}

.collab_img {
  border-radius: var(--common-border-radius);
  width: 100%;
  min-width: 300px;
  height: 323px;
  object-fit: cover;
}

.map_description {
  flex-grow: 1;
}

.phone {
  text-decoration: none;
}

@media screen and (max-width: 1140px) {
  .map {
    width: 460px;
  }
}

@media screen and (max-width: 1040px) {
  .beer_img {
    right: -400px;
  }

  .novalty_goods_box {
    gap: 60px 20px;
  }

  .promo_items_box {
    gap: 20px;
  }

  .collab_items_box {
    gap: 20px;
  }
}

@media screen and (max-width: 900px) {
  .map {
    width: 360px;
    height: 300px;
  }
}

@media screen and (max-width: 800px) {
  .beer_img {
    width: 614px;
    height: 702px;
    top: 60px;
    right: -400px;
  }

  .mission_box {
    margin-top: 60px;
  }

  .clarification_box {
    gap: 27px;
  }
}

@media screen and (max-width: 750px) {
  .slide {
    height: 391px;
  }

  .slider_text {
    font-size: 36px;
    line-height: 130%;
  }

  .slider_text {
    margin: auto 60px;
  }

  .collab_img {
    order: -1;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .subtitle {
    font-size: 36px;
    line-height: 130%;
  }

  .map_items_box {
    flex-direction: column;
  }

  .map {
    width: 100%;
    height: 440px;
  }

  .map_items_box {
    gap: 24px;
  }
}

@media screen and (max-width: 639px) {
  .clarification_box {
    flex-direction: column;
    gap: 8px;
    margin-top: 55px;
  }

  .collab_items_box {
    flex-direction: column;
  }

  .beer_img {
    top: 740px;
    right: -145px;
    width: 414px;
    height: 420px;
    transform: matrix(0.97, 0.26, -0.26, 0.97, 0, 0);
  }
}

@media screen and (max-width: 600px) {
  .mission_box {
    margin-top: 48px;
  }

  .beer_img {
    top: 720px;
  }

  .slider_box {
    margin-bottom: 0;
  }
  .novalty_goods_box {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .main_btn {
    width: 100%;
  }

  .title {
    margin-top: 24px;
  }

  .main_btn {
    font-size: 18px;
    line-height: 120%;
    height: 67px;
  }

  .beer_img {
    top: 580px;
  }

  .slide {
    height: 260px;
  }

  .slider_text {
    font-size: 24px;
    line-height: 120%;
    margin: auto 40px;
  }

  .shops_info_row {
    flex-direction: column;
    gap: 10px;
  }

  .shops_info {
    align-items: flex-start;
  }

  .phone {
    margin-top: 16px;
  }

  .map {
    height: 320px;
  }

  .collab_box {
    padding-bottom: 40px;
  }

  .mob_text {
    font-size: 16px;
    line-height: 120%;
  }
}

@media screen and (max-width: 450px) {
  .beer_img {
    top: 640px;
  }

  .mob_promo_items_box {
    display: flex;
    margin-bottom: 22px;
  }

  .promo_items_box {
    display: none;
  }

  .promo_box {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .slide {
    height: 180px;
  }

  .slider_text {
    font-size: 18px;
    margin: auto 20px;
  }

  .title {
    margin: 32px 0 16px;
  }

  .subtitle {
    font-size: 24px;
  }

  .beer_img {
    display: none;
  }

  .clarification_box {
    margin-bottom: 24px;
  }

  .map {
    height: 290px;
  }

  .mob_collab_items_box {
    display: block;
    margin-bottom: 16px;
  }

  .collab_items_box {
    display: none;
  }

  .collab_img {
    order: 0;
  }
}
