.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 40px;
  margin: 60px 0;
  background-color: var(--colors-interface-form);
  text-align: left;
  border-radius: var(--common-border-radius);
}

.ol {
  color: #000;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.li {
  margin-bottom: 20px;
}

.li_title {
  margin-bottom: 10px;
}

@media screen and (max-width: 650px) {
  .content {
    padding: 20px 20px;
    margin: 40px 0;
  }
}
