.content {
  position: fixed;
  top: 170px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  background-color: var(--background);
  z-index: 2;
  border-radius: var(--common-border-radius);
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--background);
  opacity: .4;
}
