@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo:wght@400;500;700&display=swap);
@font-face {
  font-family: "SF Pro Display";
  src: url(/static/media/SFProDisplay-Semibold.340c3a1a.woff) format("woff");
  font-weight: 600;
  font-display: swap;
}

:root {
  --background: #1d1a1a;
  --background-alt: #1c1c1c;
  --offset-base-size: 2px;

  --common-border-radius: 20px;
  --text-primary-color: #ce9d60;
  --text-secondary-color: #e0bfa0;
  --text-additional-color: #cfbc9e;
  --text-white-color: #fff;
  --text-black-color: #1c1c1c;
  --text-red-color: #c83f3b;
  --text-select-color: #a29d90;
  --text-rating-color: #111;
  --text-input-color: #787878;
  --text-stock-color: #9f403d;
  --text-link-color: #88c2f9;
  --colors-interface-form: #f5f1e3;
  --colors-button-hover: #a86c21;
  --colors-button-pressed: #7e4600;
  --colors-checkbox: #5bb0ff;
  --colors-checkbox-border: #dddbcb;
  --colors-border-default: #dae2e8;
  --colors-border-correct: #0ab025;
  --colors-slider-buttons: #918e86;

  --scrollbar-color: var(--text-primary-color) var(--text-input-color);
  --scrollbar-width: thin;

  --common-transition: all 0.3s ease-in;
}

@-moz-document url-prefix() {
  :root {
    scrollbar-color: var(--scrollbar-color);
  }
}

body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: var(--text-input-color);
}
body::-webkit-scrollbar-thumb {
  background: var(--text-primary-color);
  border: 3px solid var(--text-input-color);
  border-radius: 6px;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: var(--text-primary-color);
  background: var(--background);
}

/* Typography classes */

.text {
  margin: 0;
  padding: 0;
}

.text_color_primary {
  color: var(--text-primary-color);
}

.text_color_secondary {
  color: var(--text-secondary-color);
}

.text_color_black {
  color: var(--text-black-color);
}

.text_color_white {
  color: var(--text-white-color);
}

.text_color_red {
  color: var(--text-red-color);
}

.text_color_select {
  color: var(--text-select-color);
}

.text_color_input {
  color: var(--text-input-color);
}

.text_color_rating {
  color: var(--text-rating-color);
}

.text_color_additional {
  color: var(--text-additional-color);
}

.text_color_checkbox {
  color: var(--colors-checkbox);
}

.text_color_stock {
  color: var(--text-stock-color);
}

.text_color_link {
  color: var(--text-link-color);
}

.text_type_h1 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 140%;
}

.text_type_h2 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
}

.text_type_h3 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.text_type_cart {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}

.text_type_goods-count {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
}

.text_type_large {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
}

.text_type_large-semibold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

.text_type_order {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
}

.text_type_medium {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.text_type_medium-600 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
}

.text_type_medium-bold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text_type_sorting {
  font-family: "Exo", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.text_type_sorting-bold {
  font-family: "Exo", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.text_type_small {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}

.text_type_btn-cart {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.text_type_points_number {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 102px;
  line-height: 140%;
  letter-spacing: 0.9px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 900px) {
  .text_type_points_number {
    font-size: 80px;
    line-height: 140%;
  }
}

@media screen and (max-width: 700px) {
  .text_type_h1 {
    line-height: 120%;
  }

  .text_type_h2 {
    font-size: 24px;
    line-height: 120%;
  }
}

@media screen and (max-width: 500px) {
  .text_type_h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 400px) {
  .text_type_points_number {
    font-size: 102px;
  }
}

/* padding */
.p-1 {
    padding: var(--offset-base-size);
}
.p-2 {
    padding: calc(var(--offset-base-size)  * 2);
}
.p-3 {
    padding: calc(var(--offset-base-size)  * 3);
}
.p-4 {
    padding: calc(var(--offset-base-size)  * 4);
}
.p-5 {
    padding: calc(var(--offset-base-size)  * 5);
}
.p-6 {
    padding: calc(var(--offset-base-size)  * 6);
}
.p-7 {
    padding: calc(var(--offset-base-size)  * 7);
}
.p-8 {
    padding: calc(var(--offset-base-size)  * 8);
}
.p-9 {
    padding: calc(var(--offset-base-size)  * 9);
}
.p-10 {
    padding: calc(var(--offset-base-size)  * 10);
}
.p-12 {
    padding: calc(var(--offset-base-size)  * 12);
}
.p-15 {
    padding: calc(var(--offset-base-size)  * 15);
}
.p-16 {
    padding: calc(var(--offset-base-size)  * 16);
}
.p-20 {
    padding: calc(var(--offset-base-size)  * 20);
}
.p-25 {
    padding: calc(var(--offset-base-size)  * 25);
}
.p-30 {
    padding: calc(var(--offset-base-size)  * 30);
}
.p-35 {
    padding: calc(var(--offset-base-size)  * 35);
}
.p-40 {
    padding: calc(var(--offset-base-size)  * 40);
}

.pl-1 {
    padding-left: var(--offset-base-size);
}
.pl-2 {
    padding-left: calc(var(--offset-base-size)  * 2);
}
.pl-3 {
    padding-left: calc(var(--offset-base-size)  * 3);
}
.pl-4 {
    padding-left: calc(var(--offset-base-size)  * 4);
}
.pl-5 {
    padding-left: calc(var(--offset-base-size)  * 5);
}
.pl-6 {
    padding-left: calc(var(--offset-base-size)  * 6);
}
.pl-7 {
    padding-left: calc(var(--offset-base-size)  * 7);
}
.pl-8 {
    padding-left: calc(var(--offset-base-size)  * 8);
}
.pl-9 {
    padding-left: calc(var(--offset-base-size)  * 9);
}
.pl-10 {
    padding-left: calc(var(--offset-base-size)  * 10);
}
.pl-12 {
    padding-left: calc(var(--offset-base-size)  * 12);
}
.pl-15 {
    padding-left: calc(var(--offset-base-size)  * 15);
}
.pl-16 {
    padding-left: calc(var(--offset-base-size)  * 16);
}
.pl-20 {
    padding-left: calc(var(--offset-base-size)  * 20);
}
.pl-25 {
    padding-left: calc(var(--offset-base-size)  * 25);
}
.pl-30 {
    padding-left: calc(var(--offset-base-size)  * 30);
}
.pl-35 {
    padding-left: calc(var(--offset-base-size)  * 35);
}
.pl-40 {
    padding-left: calc(var(--offset-base-size)  * 40);
}

.pr-1 {
    padding-right: var(--offset-base-size);
}
.pr-2 {
    padding-right: calc(var(--offset-base-size)  * 2);
}
.pr-3 {
    padding-right: calc(var(--offset-base-size)  * 3);
}
.pr-4 {
    padding-right: calc(var(--offset-base-size)  * 4);
}
.pr-5 {
    padding-right: calc(var(--offset-base-size)  * 5);
}
.pr-6 {
    padding-right: calc(var(--offset-base-size)  * 6);
}
.pr-7 {
    padding-right: calc(var(--offset-base-size)  * 7);
}
.pr-8 {
    padding-right: calc(var(--offset-base-size)  * 8);
}
.pr-9 {
    padding-right: calc(var(--offset-base-size)  * 9);
}
.pr-10 {
    padding-right: calc(var(--offset-base-size)  * 10);
}
.pr-12 {
    padding-right: calc(var(--offset-base-size)  * 12);
}
.pr-15 {
    padding-right: calc(var(--offset-base-size)  * 15);
}
.pr-16 {
    padding-right: calc(var(--offset-base-size)  * 16);
}
.pr-20 {
    padding-right: calc(var(--offset-base-size)  * 20);
}
.pr-25 {
    padding-right: calc(var(--offset-base-size)  * 25);
}
.pr-30 {
    padding-right: calc(var(--offset-base-size)  * 30);
}
.pr-35 {
    padding-right: calc(var(--offset-base-size)  * 35);
}
.pr-40 {
    padding-right: calc(var(--offset-base-size)  * 40);
}

.pt-1 {
    padding-top: var(--offset-base-size);
}
.pt-2 {
    padding-top: calc(var(--offset-base-size)  * 2);
}
.pt-3 {
    padding-top: calc(var(--offset-base-size)  * 3);
}
.pt-4 {
    padding-top: calc(var(--offset-base-size)  * 4);
}
.pt-5 {
    padding-top: calc(var(--offset-base-size)  * 5);
}
.pt-6 {
    padding-top: calc(var(--offset-base-size)  * 6);
}
.pt-7 {
    padding-top: calc(var(--offset-base-size)  * 7);
}
.pt-8 {
    padding-top: calc(var(--offset-base-size)  * 8);
}
.pt-9 {
    padding-top: calc(var(--offset-base-size)  * 9);
}
.pt-10 {
    padding-top: calc(var(--offset-base-size)  * 10);
}
.pt-12 {
    padding-top: calc(var(--offset-base-size)  * 12);
}
.pt-15 {
    padding-top: calc(var(--offset-base-size)  * 15);
}
.pt-16 {
    padding-top: calc(var(--offset-base-size)  * 16);
}
.pt-20 {
    padding-top: calc(var(--offset-base-size)  * 20);
}
.pt-25 {
    padding-top: calc(var(--offset-base-size)  * 25);
}
.pt-30 {
    padding-top: calc(var(--offset-base-size)  * 30);
}
.pt-35 {
    padding-top: calc(var(--offset-base-size)  * 35);
}
.pt-40 {
    padding-top: calc(var(--offset-base-size)  * 40);
}

.pb-1 {
    padding-bottom: var(--offset-base-size);
}
.pb-2 {
    padding-bottom: calc(var(--offset-base-size)  * 2);
}
.pb-3 {
    padding-bottom: calc(var(--offset-base-size)  * 3);
}
.pb-4 {
    padding-bottom: calc(var(--offset-base-size)  * 4);
}
.pb-5 {
    padding-bottom: calc(var(--offset-base-size)  * 5);
}
.pb-6 {
    padding-bottom: calc(var(--offset-base-size)  * 6);
}
.pb-7 {
    padding-bottom: calc(var(--offset-base-size)  * 7);
}
.pb-8 {
    padding-bottom: calc(var(--offset-base-size)  * 8);
}
.pb-9 {
    padding-bottom: calc(var(--offset-base-size)  * 9);
}
.pb-10 {
    padding-bottom: calc(var(--offset-base-size)  * 10);
}
.pb-12 {
    padding-bottom: calc(var(--offset-base-size)  * 12);
}
.pb-15 {
    padding-bottom: calc(var(--offset-base-size)  * 15);
}
.pb-16 {
    padding-bottom: calc(var(--offset-base-size)  * 16);
}
.pb-20 {
    padding-bottom: calc(var(--offset-base-size)  * 20);
}
.pb-25 {
    padding-bottom: calc(var(--offset-base-size)  * 25);
}
.pb-30 {
    padding-bottom: calc(var(--offset-base-size)  * 30);
}
.pb-35 {
    padding-bottom: calc(var(--offset-base-size)  * 35);
}
.pb-40 {
    padding-bottom: calc(var(--offset-base-size)  * 40);
}

/* margin */

.m-1 {
    margin: var(--offset-base-size);
}
.m-2 {
    margin: calc(var(--offset-base-size)  * 2);
}
.m-3 {
    margin: calc(var(--offset-base-size)  * 3);
}
.m-4 {
    margin: calc(var(--offset-base-size)  * 4);
}
.m-5 {
    margin: calc(var(--offset-base-size)  * 5);
}
.m-6 {
    margin: calc(var(--offset-base-size)  * 6);
}
.m-7 {
    margin: calc(var(--offset-base-size)  * 7);
}
.m-8 {
    margin: calc(var(--offset-base-size)  * 8);
}
.m-9 {
    margin: calc(var(--offset-base-size)  * 9);
}
.m-10 {
    margin: calc(var(--offset-base-size)  * 10);
}
.m-12 {
    margin: calc(var(--offset-base-size)  * 12);
}
.m-15 {
    margin: calc(var(--offset-base-size)  * 15);
}
.m-16 {
    margin: calc(var(--offset-base-size)  * 16);
}
.m-20 {
    margin: calc(var(--offset-base-size)  * 20);
}
.m-25 {
    margin: calc(var(--offset-base-size)  * 25);
}
.m-30 {
    margin: calc(var(--offset-base-size)  * 30);
}
.m-35 {
    margin: calc(var(--offset-base-size)  * 35);
}
.m-40 {
    margin: calc(var(--offset-base-size)  * 40);
}

.ml-1 {
    margin-left: var(--offset-base-size);
}
.ml-2 {
    margin-left: calc(var(--offset-base-size)  * 2);
}
.ml-3 {
    margin-left: calc(var(--offset-base-size)  * 3);
}
.ml-4 {
    margin-left: calc(var(--offset-base-size)  * 4);
}
.ml-5 {
    margin-left: calc(var(--offset-base-size)  * 5);
}
.ml-6 {
    margin-left: calc(var(--offset-base-size)  * 6);
}
.ml-7 {
    margin-left: calc(var(--offset-base-size)  * 7);
}
.ml-8 {
    margin-left: calc(var(--offset-base-size)  * 8);
}
.ml-9 {
    margin-left: calc(var(--offset-base-size)  * 9);
}
.ml-10 {
    margin-left: calc(var(--offset-base-size)  * 10);
}
.ml-12 {
    margin-left: calc(var(--offset-base-size)  * 12);
}
.ml-15 {
    margin-left: calc(var(--offset-base-size)  * 15);
}
.ml-16 {
    margin-left: calc(var(--offset-base-size)  * 16);
}
.ml-20 {
    margin-left: calc(var(--offset-base-size)  * 20);
}
.ml-25 {
    margin-left: calc(var(--offset-base-size)  * 25);
}
.ml-30 {
    margin-left: calc(var(--offset-base-size)  * 30);
}
.ml-35 {
    margin-left: calc(var(--offset-base-size)  * 35);
}
.ml-40 {
    margin-left: calc(var(--offset-base-size)  * 40);
}

.mr-1 {
    margin-right: var(--offset-base-size);
}
.mr-2 {
    margin-right: calc(var(--offset-base-size)  * 2);
}
.mr-3 {
    margin-right: calc(var(--offset-base-size)  * 3);
}
.mr-4 {
    margin-right: calc(var(--offset-base-size)  * 4);
}
.mr-5 {
    margin-right: calc(var(--offset-base-size)  * 5);
}
.mr-6 {
    margin-right: calc(var(--offset-base-size)  * 6);
}
.mr-7 {
    margin-right: calc(var(--offset-base-size)  * 7);
}
.mr-8 {
    margin-right: calc(var(--offset-base-size)  * 8);
}
.mr-9 {
    margin-right: calc(var(--offset-base-size)  * 9);
}
.mr-10 {
    margin-right: calc(var(--offset-base-size)  * 10);
}
.mr-12 {
    margin-right: calc(var(--offset-base-size)  * 12);
}
.mr-15 {
    margin-right: calc(var(--offset-base-size)  * 15);
}
.mr-16 {
    margin-right: calc(var(--offset-base-size)  * 16);
}
.mr-20 {
    margin-right: calc(var(--offset-base-size)  * 20);
}
.mr-25 {
    margin-right: calc(var(--offset-base-size)  * 25);
}
.mr-30 {
    margin-right: calc(var(--offset-base-size)  * 30);
}
.mr-35 {
    margin-right: calc(var(--offset-base-size)  * 35);
}
.mr-40 {
    margin-right: calc(var(--offset-base-size)  * 40);
}

.mt-1 {
    margin-top: var(--offset-base-size);
}
.mt-2 {
    margin-top: calc(var(--offset-base-size)  * 2);
}
.mt-3 {
    margin-top: calc(var(--offset-base-size)  * 3);
}
.mt-4 {
    margin-top: calc(var(--offset-base-size)  * 4);
}
.mt-5 {
    margin-top: calc(var(--offset-base-size)  * 5);
}
.mt-6 {
    margin-top: calc(var(--offset-base-size)  * 6);
}
.mt-7 {
    margin-top: calc(var(--offset-base-size)  * 7);
}
.mt-8 {
    margin-top: calc(var(--offset-base-size)  * 8);
}
.mt-9 {
    margin-top: calc(var(--offset-base-size)  * 9);
}
.mt-10 {
    margin-top: calc(var(--offset-base-size)  * 10);
}
.mt-12 {
    margin-top: calc(var(--offset-base-size)  * 12);
}
.mt-15 {
    margin-top: calc(var(--offset-base-size)  * 15);
}
.mt-16 {
    margin-top: calc(var(--offset-base-size)  * 16);
}
.mt-20 {
    margin-top: calc(var(--offset-base-size)  * 20);
}
.mt-25 {
    margin-top: calc(var(--offset-base-size)  * 25);
}
.mt-30 {
    margin-top: calc(var(--offset-base-size)  * 30);
}
.mt-35 {
    margin-top: calc(var(--offset-base-size)  * 35);
}
.mt-40 {
    margin-top: calc(var(--offset-base-size)  * 40);
}

.mb-1 {
    margin-bottom: var(--offset-base-size);
}
.mb-2 {
    margin-bottom: calc(var(--offset-base-size)  * 2);
}
.mb-3 {
    margin-bottom: calc(var(--offset-base-size)  * 3);
}
.mb-4 {
    margin-bottom: calc(var(--offset-base-size)  * 4);
}
.mb-5 {
    margin-bottom: calc(var(--offset-base-size)  * 5);
}
.mb-6 {
    margin-bottom: calc(var(--offset-base-size)  * 6);
}
.mb-7 {
    margin-bottom: calc(var(--offset-base-size)  * 7);
}
.mb-8 {
    margin-bottom: calc(var(--offset-base-size)  * 8);
}
.mb-9 {
    margin-bottom: calc(var(--offset-base-size)  * 9);
}
.mb-10 {
    margin-bottom: calc(var(--offset-base-size)  * 10);
}
.mb-12 {
    margin-bottom: calc(var(--offset-base-size)  * 12);
}
.mb-15 {
    margin-bottom: calc(var(--offset-base-size)  * 15);
}
.mb-16 {
    margin-bottom: calc(var(--offset-base-size)  * 16);
}
.mb-20 {
    margin-bottom: calc(var(--offset-base-size)  * 20);
}
.mb-25 {
    margin-bottom: calc(var(--offset-base-size)  * 25);
}
.mb-30 {
    margin-bottom: calc(var(--offset-base-size)  * 30);
}
.mb-35 {
    margin-bottom: calc(var(--offset-base-size)  * 35);
}
.mb-40 {
    margin-bottom: calc(var(--offset-base-size)  * 40);
}

.app_app__M9MMq {
  text-align: center;
}

.app_content__1uD0k {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 40px;
  overflow: hidden;
}

@media screen and (max-width: 959px) {
  .app_content__1uD0k {
    padding: 0 10px;
  }
}

.not-found-page_content__7jmhQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 395px);
}

.not-found-page_icon__2FOGw {
  width: 821px;
  height: 143px;
}

.not-found-page_text__1bzml {
  max-width: 571px;
}

@media screen and (max-width: 1000px) {
  .not-found-page_icon__2FOGw {
    width: 562px;
    height: 98px;
  }

  .not-found-page_text__1bzml {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 650px) {
  .not-found-page_content__7jmhQ {
    height: calc(100vh - 480px);
  }
}

@media screen and (max-width: 600px) {
  .not-found-page_icon__2FOGw {
    width: 401px;
    height: 70px;
  }

  .not-found-page_text__1bzml {
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .not-found-page_content__7jmhQ {
    height: calc(100vh - 483px);
  }
}

@media screen and (max-width: 400px) {
  .not-found-page_content__7jmhQ {
    height: calc(100vh - 548px);
  }

  .not-found-page_icon__2FOGw {
    width: 287px;
    height: 50px;
  }
}

.nav-button_button__2lIcO {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  transition: var(--common-transition);
  background: none;
  padding: 0;
  border-radius: var(--common-border-radius);
	letter-spacing: -0.31px;
	text-align: center;
  text-decoration: none;
  width: 240px;
  height: 44px;
}

.nav-button_form__2SI4Y {
  background-color: var(--text-primary-color);
}

.nav-button_form__2SI4Y:disabled {
  opacity: .4;
}

.nav-button_form__2SI4Y:hover {
  cursor: pointer;
  background-color: var(--colors-button-hover);
}

.nav-button_form__2SI4Y:active {
  background-color: var(--colors-button-pressed);
}


.nav-button_cart__2_L1u {
  background-color: var(--text-white-color);
  color: var(--text-black-color);
}

.nav-button_cart__2_L1u:hover {
  cursor: pointer;
  opacity: .7;
}
.loyalty-page_content__3esJR {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loyalty-page_main_content__3zPYE {
  display: flex;
}

.loyalty-page_right_column__3BWmd {
  max-width: 57%;
}

.loyalty-page_text_box__3gkA4 {
  max-width: 444px;
  letter-spacing: -0.31px;
  text-align: left;
  margin-left: 30px;
  margin-right: -14px;
}

.loyalty-page_img__59hKH {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.loyalty-page_vertical_img__rv9Fp {
  display: none;
  width: 100%;
  height: 581px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

@media screen and (max-width: 900px) {
  .loyalty-page_main_content__3zPYE {
    flex-direction: column;
    width: 100%;
  }

  .loyalty-page_img__59hKH {
    display: none;
  }

  .loyalty-page_vertical_img__rv9Fp {
    display: block;
  }

  .loyalty-page_text_box__3gkA4 {
    max-width: 100%;
    margin-bottom: 32px;
    padding-top: 24px;
  }

  .loyalty-page_text__3DY5P {
    width: calc(100% - 20px);
  }

  .loyalty-page_left_column__2y0r8 {
    margin-right: 0;
    width: 100%;
  }

  .loyalty-page_form__gcXED {
    max-width: 460px;
    margin: 32px auto 0;
  }
}

@media screen and (max-width: 500px) {
  .loyalty-page_vertical_img__rv9Fp {
    height: 430px;
  }
}

@media screen and (max-width: 400px) {
  .loyalty-page_title__1-7-_ {
    text-align: left;
  }

  .loyalty-page_text_box__3gkA4 {
    margin-bottom: 16px;
  }

  .loyalty-page_vertical_img__rv9Fp {
    height: 280px;
  }

  .loyalty-page_form__gcXED {
    margin-top: 24px;
  }

  .loyalty-page_main_content__3zPYE {
    margin-bottom: 40px;
  }
}

.form-container_content__3hUzK {
  position: relative;
  padding: 24px;
  border-radius: var(--common-border-radius);
  background-color: var(--colors-interface-form);
  -webkit-backdrop-filter: blur(132px);
          backdrop-filter: blur(132px);
}

.form-container_btn__CC2wt {
  position: absolute;
  top: 24px;
  right: 37px;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: var(--common-transition);
  z-index: 1;
}

.form-container_btn__CC2wt:hover {
  opacity: .7;
}

.form-container_img__1XryY {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .form-container_btn__CC2wt {
    right: 24px;
  }
}

.input_content__WLGyL {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input_input__1P--v {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
}

.input_input_phone__9BV8F {
  width: 100%;
  height: 40px;
}

.input_label__3Itj8 {
  text-align: start;
}

.input_form__1nfVD {
  width: calc(100% - 32px);
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--colors-border-default);
}

.input_form__1nfVD:focus {
  border: 1px solid var(--colors-checkbox-border);
}

/* .form:valid {
  border: 1px solid var(--colors-border-correct);
} */

.input_form__1nfVD:invalid {
  border: 1px solid var(--text-stock-color);
}

.input_form__1nfVD:disabled {
  border: 1px solid var(--colors-checkbox-border);
}

.input_search__3ZFD9 {
  position: relative;
  width: calc(100% - 70px);
  height: 30px;
  background-color: var(--background);
  border: 1px solid var(--text-select-color);
  padding: 0 34px;
  background: url(/static/media/search.b1781e2c.svg) no-repeat scroll 8px 6px;
}

.input_search__3ZFD9::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-select-color);
}

.input_eye_btn__1McN9 {
  position: absolute;
  top: 36px;
  right: 8px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.input_error__2DECs {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: red;
  margin-top: 10px;
  text-align: left;
}

.button_button__1yjAB {
  border: none;
  outline: none;
  transition: var(--common-transition);
  background: none;
  padding: 0;
  border-radius: var(--common-border-radius);
  width: 240px;
  height: 44px;
	letter-spacing: -0.31px;
	text-align: center;
  font-weight: normal;
}

.button_button__1yjAB:disabled {
  opacity: .5;
}

.button_form__XL3BN {
  background-color: var(--text-primary-color);
  min-height: 44px;
}

.button_form__XL3BN:disabled {
  opacity: .4;
}

.button_form__XL3BN:hover:enabled {
  cursor: pointer;
  background-color: var(--colors-button-hover);
}

.button_form__XL3BN:active:enabled {
  background-color: var(--colors-button-pressed);
}


.button_cart__3QeM8 {
  background-color: var(--text-white-color);
  color: var(--text-black-color);
}

.button_cart__3QeM8:hover:enabled {
  cursor: pointer;
  opacity: .7;
}

.sign-up_form__Vahsl {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sign-up_title__1rxVU {
  align-self: center;
}

.sign-up_policy_box__39xS- {
  margin-bottom: 70px;
  text-align: left;
}

.sign-up_policy_row__3kZaD {
  display: flex;
  align-items: flex-start;
}

.sign-up_checkbox__3QgAd {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.sign-up_policy__2dHdf {
  cursor: pointer;
  text-align: left;
}

.sign-up_link__3icdK {
  text-decoration: none;
}

.sign-up_btn__TaIba {
  align-self: center;
}

.sign-up_reactivate_btn__NC0F_ {
  align-self: center;
  padding: 0;
  background: none;
}

.sign-up_error__1sUmg {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.sign-up_login_box__2n1pM {
  align-self: center;
  margin-top: 10px;
}

.sign-up_login_btn__24WfC {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: var(--common-transition);
}

.sign-up_login_btn__24WfC:hover {
  opacity: .7;
}

.sign-up_recaptcha__4pVnI {
  margin: 0 auto 20px;
}

.sign-up_reactivate_box__1OLMh {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bread-crumbs_content__13_iw {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  flex-wrap: wrap;
}

.bread-crumbs_link__gf-lm {
  display: flex;
  text-decoration: none;
}

.bread-crumbs_hidden__2PhZr {
  display: none;
}

.bread-crumbs_back_img__1zgTz {
  display: none;
}

@media screen and (max-width: 500px) {
  .bread-crumbs_mobile__12VVs {
    display: none;
  }

  .bread-crumbs_back_img__1zgTz {
    display: block;
  }
}

.partnership-page_content__1FtyD {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px 41px;
  gap: 64px 41px;
  margin-top: 32px;
  margin-bottom: 60px;
}

.partnership-page_info_box__1mo2c {
  text-align: left;
}

.partnership-page_text_box__5_sHd {
  padding-left: 30px;
}

.partnership-page_img__3zZor {
  width: 100%;
  height: 413px;
  object-fit: cover;
  object-position: center top;
  border-radius: var(--common-border-radius);
}

.partnership-page_vertical_img__7Urvv {
  display: none;
  width: 100%;
  height: 526px;
  object-fit: cover;
  object-position: center top;
  border-radius: var(--common-border-radius);
}

@media screen and (max-width: 800px) {
  .partnership-page_content__1FtyD {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    gap: 32px;
    margin-top: 24px;
  }

  .partnership-page_img__3zZor {
    display: none;
  }

  .partnership-page_vertical_img__7Urvv {
    display: block;
    margin-bottom: 16px;
  }

  .partnership-page_title__2LoDY {
    margin-bottom: 16px;
  }

  .partnership-page_text__2xokP {
    margin: 0 0 24px;
  }

  .partnership-page_text__2xokP:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .partnership-page_vertical_img__7Urvv {
    height: 390px;
  }

  .partnership-page_content__1FtyD {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 400px) {
  .partnership-page_vertical_img__7Urvv {
    height: 254px;
  }
}

.goods-card-page_content__1qotp {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
  .goods-card-page_content__1qotp {
    margin-bottom: 40px;
  }
}

.goods-count_container__MfyXd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 132px;
  height: 34px;
  padding: 5px;
  background-color: var(--text-white-color);
  border-radius: var(--common-border-radius);
}

.goods-count_button__i2M5P {
  border: 1px solid var(--text-primary-color);
  outline: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  background-color: var(--text-primary-color);
  color: var(--colors-interface-form);
  text-align: center;
  padding: 0;
  margin: 0;
  transition: var(--common-transition);
}

.goods-count_button__i2M5P:disabled {
  opacity: .4;
}

.goods-count_button__i2M5P:hover:enabled {
  cursor: pointer;
  background-color: var(--colors-button-hover);
}

.goods-count_button__i2M5P:active:enabled {
  background-color: var(--colors-button-pressed);
}


.rating_content__3mRLP {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-white-color);
  border-radius: var(--common-border-radius);
  width: 80px;
  height: 32px;
}

.rating_icon__2JXoM {
  width: 15px;
}

.modal_content__3F4IX {
  position: fixed;
  top: 170px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  background-color: var(--background);
  z-index: 2;
  border-radius: var(--common-border-radius);
}

.modal_overlay__1Sgwx {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--background);
  opacity: .4;
}

.sign-in_form__3pqiZ {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 486px;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sign-in_title__3tvZ0 {
  align-self: center;
}

.sign-in_remember_box__2FjuE {
  display: flex;
  align-items: flex-start;
}

.sign-in_checkbox__1nTKS {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.sign-in_remember__3V-Nm {
  cursor: pointer;
  text-align: left;
}

.sign-in_link__2KDyu {
  text-decoration: none;
}

.sign-in_btn__2Oi8N {
  align-self: center;
}

.sign-in_btn__2Oi8N:first-of-type {
  margin-bottom: 16px;
}

.sign-in_forgot_btn__15Y38 {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: var(--common-transition);
}

.sign-in_forgot_btn__15Y38:hover {
  opacity: 0.7;
}

.sign-in_error__1-Fr0 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: red;
  margin-bottom: 10px;
}

.sign-in_reactivate_box__21s7g {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in_reactivate_btn__Hi-5x {
  padding: 0;
  background: none;
  align-self: center;
}

.sign-in_recaptcha__3EOgd {
  margin: 0 auto 20px;
}

.goods-details-card_content__1es52 {
  display: flex;
  flex-direction: column;
  max-width: 1160px;
}

.goods-details-card_goods_info__39kC2 {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 32px;
}

.goods-details-card_img_box__1Imdp {
  position: relative;
  min-width: 360px;
  width: 360px;
  height: 100%;
}

.goods-details-card_img__2WQGV {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.goods-details-card_rating__2Rk0o {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.goods-details-card_info_box__3Fmnw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  flex-grow: 1;
}

.goods-details-card_info__3FMFD {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
}

.goods-details-card_info_row__2qhyn {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 50px;
  gap: 50px;
}

.goods-details-card_btns_n_price_box__12EAu {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  margin-top: auto;
}

.goods-details-card_price_box__2-pOj {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.goods-details-card_btns_box__2jjSb {
  display: flex;
}

.goods-details-card_btn_to_filters__15V0K {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  transition: var(--common-transition);
}

.goods-details-card_btn_to_filters__15V0K:hover {
  opacity: .7;
}

.goods-details-card_description_box__2PFbr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.goods-details-card_description__2DTP4 {
  max-width: 660px;
}

.goods-details-card_addit_btns_n_price_box__3Ju7e {
  display: none;
}

.goods-details-card_mob_title__GIqun {
  display: none;
}

.goods-details-card_mob_info__1424q {
  display: none;
}

.goods-details-card_modal__uoQqt {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.goods-details-card_reg_form__2belf {
  width: 486px;
}

@media screen and (max-width: 1050px) {
  .goods-details-card_goods_info__39kC2 {
    grid-gap: 20px;
    gap: 20px;
  }

  .goods-details-card_img_box__1Imdp {
    min-width: 300px;
    width: 300px;
  }

  .goods-details-card_btns_n_price_box__12EAu {
    display: none;
  }

  .goods-details-card_addit_btns_n_price_box__3Ju7e {
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
  }

  .goods-details-card_price_box__2-pOj {
    text-align: left;
  }
}

@media screen and (max-width: 750px) {
  .goods-details-card_info__3FMFD {
    grid-gap: 8px;
    gap: 8px;
  }

  .goods-details-card_info_row__2qhyn {
    grid-template-columns: 130px 1fr;
    grid-gap: 30px;
    gap: 30px;
  }

  .goods-details-card_img_box__1Imdp {
    min-width: 220px;
    width: 220px;
  }
}

@media screen and (max-width: 580px) {
  .goods-details-card_info__3FMFD {
    display: none;
  }

  .goods-details-card_img_box__1Imdp {
    height: 265px;
    overflow: hidden;
  }

  .goods-details-card_btns_n_price_box__12EAu {
    display: flex;
  }

  .goods-details-card_btns_box__2jjSb {
    display: none;
  }

  .goods-details-card_price__3bHgG {
    color: var(--text-primary-color);
  }

  .goods-details-card_stock__1pVzU {
    margin-bottom: 12px;
  }

  .goods-details-card_title__3ybui {
    margin-bottom: 8px;
  }

  .goods-details-card_mob_price_box__FQzW9 {
    display: none;
  }

  .goods-details-card_mob_btns_box__2WTjf {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 26px;
  }

  .goods-details-card_mob_info__1424q {
    display: grid;
    text-align: left;
    margin-top: 32px;
  }

  .goods-details-card_description_box__2PFbr {
    margin-top: 24px;
  }

  .goods-details-card_reg_form__2belf {
    width: 386px;
  }
}

@media screen and (max-width: 450px) {
  .goods-details-card_goods_info__39kC2 {
    flex-direction: column;
    align-items: center;
  }

  .goods-details-card_title__3ybui {
    display: none;
  }

  .goods-details-card_mob_title__GIqun {
    display: block;
    text-align: left;
    margin-bottom: 0;
  }

  .goods-details-card_img_box__1Imdp {
    max-width: 100%;
    width: 100%;
    height: 400px;
  }

  .goods-details-card_info_box__3Fmnw {
    width: 100%;
    align-items: flex-start;
  }

  .goods-details-card_stock__1pVzU {
    order: 1;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .goods-details-card_price__3bHgG {
    color: var(--text-white-color);
  }

  .goods-details-card_btns_box__2jjSb {
    margin-top: 24px;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }

  .goods-details-card_right_column_text__3XxyF {
    text-align: right;
  }

  .goods-details-card_info_row__2qhyn {
    grid-template-columns: 100px 1fr;
  }

  .goods-details-card_reg_form__2belf {
    width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .goods-details-card_reg_form__2belf {
    width: 252px;
  }
}

@media screen and (max-width: 350px) {
  .goods-details-card_img_box__1Imdp {
    height: 359px;
  }
}

.cart-page_content__2C0_c {
  display: flex;
  flex-direction: column;
}

.cart-page_law_box__PjPFW {
  background-color: var(--colors-checkbox-border);
  text-align: left;
  margin-top: 10px;
  border-radius: 10px;
}

.cart-page_law_text__9MsSd {
  max-width: 1160px;
  margin: 0 auto;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}

.cart-goods_content__3jW5u {
  background-color: var(--colors-interface-form);
  border-radius: var(--common-border-radius);
}

.cart-goods_main_content__IYSTM {
  display: flex;
  align-items: center;
}

.cart-goods_img__1WKcp {
  width: 165px;
  min-width: 165px;
  height: 158px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.cart-goods_main_info__Hbluj {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.cart-goods_name_box__1yiJf {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cart-goods_description__2XXgp {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.cart-goods_info__1d45n {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.cart-goods_del_btn__2RjX4 {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

.cart-goods_del_img__CsUBr {
  width: 32px;
  height: 32px;
}

.cart-goods_name__bHqvd {
  text-align: left;
}

.cart-goods_price__kZMKw {
  min-width: 100px;
  text-align: right;
}

.cart-goods_mob_price__EFVli {
  display: none;
}

.cart-goods_mob_row__eJCA5 {
  display: none;
}

.cart-goods_price_del_box__3BVuq {
  display: flex;
  margin-left: auto;
}

@media screen and (max-width: 1020px) {
  .cart-goods_description__2XXgp {
    justify-content: normal;
  }

  .cart-goods_price__kZMKw {
    display: none;
  }

  .cart-goods_mob_price__EFVli {
    display: block;
    margin-right: 20px;
    margin-left: auto;
  }
}

@media screen and (max-width: 900px) {
  .cart-goods_mob_price__EFVli {
    margin-right: 60px;
  }
}

@media screen and (max-width: 630px) {
  .cart-goods_main_content__IYSTM {
    align-items: flex-start;
  }

  .cart-goods_description__2XXgp {
    justify-content: normal;
  }

  .cart-goods_price__kZMKw {
    display: none;
  }

  .cart-goods_mob_row__eJCA5 {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .cart-goods_hidden__2DtDH {
    display: none;
  }

  .cart-goods_img__1WKcp {
    margin-bottom: 24px;
    margin-right: 16px;
    width: 135px;
    min-width: 135px;
    height: 130px;
  }
}

@media screen and (max-width: 415px) {
  .cart-goods_main_content__IYSTM {
    flex-direction: column;
    align-items: center;
  }

  .cart-goods_mob_row__eJCA5 {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .cart-goods_price_del_box__3BVuq {
    margin-left: 0;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }

  .cart-goods_mob_price__EFVli {
    margin: 0;
  }
}

.order-cost-card_content__1McX7 {
  display: flex;
  flex-direction: column;
  min-width: 252px;
}

.order-cost-card_cost_box__3bGyg {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-cost-card_points_box__1Jhbh {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.order-cost-card_checkbox__1dpMQ {
  margin-top: 26px;
}

.order-cost-card_title__JorUt {
  align-self: center;
}

.order-cost-card_btn__8wFyd {
  align-self: center;
  margin-top: 32px;
}

.order-cost-card_shop_text__12VGS {
  text-align: center;
}

.order-cost-card_modal__1uNv5 {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.order-cost-card_form_modal__3giUv {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

@media screen and (max-width: 630px) {
  .order-cost-card_content__1McX7 {
    min-width: 100%;
  }

  .order-cost-card_title__JorUt {
    align-self: flex-start;
    margin-bottom: 27px;
  }

  .order-cost-card_points_box__1Jhbh {
    margin-top: 20px;
  }

  .order-cost-card_checkbox__1dpMQ {
    margin-top: 18px;
  }

  .order-cost-card_mob_input__1VHPc {
    margin-top: 16px;
  }

  .order-cost-card_btn__8wFyd {
    margin-top: 24px;
  }
}

.reservation-card_content__2_qCK {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 16px 0 40px;
}

.reservation-card_text__2Vb9X {
  max-width: 635px;
}

.reservation-card_big_text__2pijA {
  font-weight: normal;
  line-height: 140%;
  letter-spacing: 0.37px;
}

.reservation-card_main_content__3SHH8 {
  display: grid;
  grid-template-columns: 1fr 360px;
  grid-gap: 40px;
  gap: 40px;
}

.reservation-card_goods_cards__1PcbF {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.reservation-card_forms__2G5Lx {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.reservation-card_modal__3VH6v {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  max-width: 300px;
}

.reservation-card_alarm_box__1mFX4 {
  text-align: center;
  padding-right: 70px;
}

.reservation-card_reg_text__3CCgP {
  display: none;
}

@media screen and (max-width: 1100px) {
  .reservation-card_main_content__3SHH8 {
    grid-template-columns: 1fr 300px;
  }
}

@media screen and (max-width: 900px) {
  .reservation-card_main_content__3SHH8 {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
  }

  .reservation-card_forms__2G5Lx {
    flex-direction: row;
    grid-gap: 19px;
    gap: 19px;
    align-items: flex-start;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .reservation-card_title__3jP-y {
    margin-bottom: 8px;
  }

  .reservation-card_big_text__2pijA {
    font-size: 36px;
    line-height: 130%;
  }
}

@media screen and (max-width: 630px) {
  .reservation-card_signup__ToWxY {
    display: none;
  }
  
  .reservation-card_forms__2G5Lx {
    flex-direction: column;
    align-items: normal;
  }

  .reservation-card_reg_text__3CCgP {
    display: block;
  }

  .reservation-card_reg_link__1ZnXz {
    text-decoration: none;
  }

  .reservation-card_forms__2G5Lx {
    grid-gap: 16px;
    gap: 16px;
  }
  
  .reservation-card_title2__3tNy0 {
    margin-bottom: 10px;
  }

  .reservation-card_goods_cards__1PcbF {
    grid-gap: 16px;
    gap: 16px;
  }
}

.order_content__1X1ew {
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
  width: 100%;
}

.order_main_row__1Rsqb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_info_box__15FN6 {
  display: flex;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.order_btn__180Kt {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: auto 0;
  width: 20px;
  height: 15px;
  transition: var(--common-transition);
}

.order_btn__180Kt:hover {
  opacity: .7;
}

.order_btn_cross__xkDa8 {
  width: 15px;
  height: 15px;
}

.order_img__8JssA {
  width: 100%;
  height: 100%;
}

.order_done_img__1YGOE {
  width: 20px;
  height: 18px;
}

.order_details_box__2BmC2 {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  text-align: left;
}

.order_goods_name__2qVXU {
  max-width: 268px;
}

.order_amount_box__18XHk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.order_sub_row__2b1QI {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
}

.order_modal__KdW1X {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.order_form_modal__3FZIc {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

@media screen and (max-width: 950px) {
  .order_sub_row__2b1QI {
    grid-gap: 24px;
    gap: 24px;
  }
}

@media screen and (max-width: 600px) {
  .order_info_box__15FN6 {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {
  .order_main_row__1Rsqb {
    flex-direction: column;
    align-items: flex-start;
  }

  .order_sub_row__2b1QI {
    align-self: flex-end;
    grid-gap: 32px;
    gap: 32px;
  }

  .order_details_box__2BmC2 {
    padding-right: 0;
    grid-gap: 10px;
    gap: 10px;
  }

  .order_amount_box__18XHk {
    min-width: 60px;
  }
}

.personal-area-page_content__1wDS8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: calc(100vh - 160px - 232px);
}

.personal-area-page_header_box__34GN5 {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}

.personal-area-page_logout__L-Xrr {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  margin: 0;
  transition: var(--common-transition);
  cursor: pointer;
}

.personal-area-page_logout__L-Xrr:hover {
  opacity: 0.7;
}

.personal-area-page_greeting__2tRhQ {
  width: calc(100% - 48px);
  text-align: left;
  margin-bottom: 32px;
}

.personal-area-page_main_content__2dmjD {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  width: 100%;
}

.personal-area-page_right_column__2rIey {
  display: flex;
  flex-direction: column;
  grid-gap: 26px;
  gap: 26px;
  width: 100%;
  max-width: 660px;
}

.personal-area-page_data_title_box__3Z9oB {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.personal-area-page_data_box__3QR1B {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.personal-area-page_user_data__1d6IW {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.personal-area-page_edit_btn__4ZzNF {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: auto 0;
  width: 24px;
  height: 24px;
  transition: var(--common-transition);
}

.personal-area-page_edit_btn__4ZzNF:hover {
  opacity: 0.7;
}

.personal-area-page_data_connection_box__kHKwo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 24px;
  gap: 24px;
}

.personal-area-page_email_font__3jpxp {
  letter-spacing: -0.31px;
}

.personal-area-page_history__16_zL {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 32px;
  min-width: 396px;
}

.personal-area-page_history_title_box__3wgjs {
  display: flex;
  justify-content: space-between;
}

.personal-area-page_history_btn__3yp5Z {
  width: 130px;
  height: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.personal-area-page_ao_order__2GTGf {
  max-width: 65%;
}

.personal-area-page_loyalty_card_box__33jsF {
  display: grid;
  max-width: 960px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 60px;
}

.personal-area-page_lcb_left_column__2Bbxy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.personal-area-page_lcb_card__1Zoak {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 460px;
  min-width: 460px;
  background-image: url(/static/media/loyalty-bg.c76c19a7.png);
  height: 238px;
  border-radius: var(--common-border-radius);
}

.personal-area-page_lcb_logo__3PydD {
  width: 290px;
}

.personal-area-page_lcb_right_column__Y3_JF {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 460px;
  text-align: left;
}

.personal-area-page_mob_history_btn__3XCSL {
  display: none;
}

.personal-area-page_log_btn__VH6Uc {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  transition: var(--common-transition);
  font-weight: bold;
}

.personal-area-page_log_btn__VH6Uc:hover {
  opacity: 0.7;
}

.personal-area-page_modal__tRNPk {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.personal-area-page_reg_form__2pHCF {
  max-width: 486px;
  min-width: 432px;
}

.personal-area-page_shop__1Gmm_ {
  text-align: left;
}

@media screen and (max-width: 1150px) {
  .personal-area-page_data_title_box__3Z9oB {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .personal-area-page_ao_order__2GTGf {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .personal-area-page_main_content__2dmjD {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 32px;
  }

  .personal-area-page_right_column__2rIey {
    grid-gap: 16px;
    gap: 16px;
    max-width: 100%;
  }

  .personal-area-page_data_title_box__3Z9oB {
    flex-direction: row;
    align-items: normal;
    grid-gap: normal;
    gap: normal;
  }

  .personal-area-page_ao_order__2GTGf {
    max-width: 550px;
  }

  .personal-area-page_ho_order__RhKKd {
    max-width: 550px;
  }

  .personal-area-page_loyalty_card_box__33jsF {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    gap: 32px;
  }
}

@media screen and (max-width: 650px) {
  .personal-area-page_content__1wDS8 {
    min-height: calc(100vh - 160px - 320px);
  }
}

@media screen and (max-width: 600px) {
  .personal-area-page_data_title_box__3Z9oB {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .personal-area-page_ao_order__2GTGf {
    max-width: 100%;
  }

  .personal-area-page_history__16_zL {
    min-width: 350px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .personal-area-page_data_connection_box__kHKwo {
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
  }

  .personal-area-page_lcb_card__1Zoak {
    min-width: 398px;
  }
}

@media screen and (max-width: 500px) {
  .personal-area-page_reg_form__2pHCF {
    min-width: 400px;
  }

  .personal-area-page_header_box__34GN5 {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 470px) {
  .personal-area-page_reg_form__2pHCF {
    min-width: 370px;
  }
}

@media screen and (max-width: 430px) {
  .personal-area-page_reg_form__2pHCF {
    min-width: 252px;
  }
}

@media screen and (max-width: 450px) {
  .personal-area-page_history_btn__3yp5Z {
    display: none;
  }

  .personal-area-page_mob_history_btn__3XCSL {
    display: block;
    align-self: center;
    margin-top: 40px;
  }

  .personal-area-page_history__16_zL {
    min-width: 252px;
  }

  .personal-area-page_lcb_card__1Zoak {
    min-width: 300px;
    height: 176px;
  }

  .personal-area-page_lcb_logo__3PydD {
    width: 220px;
  }
}

.history-modal_content__264Yb {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
}

.history-modal_data_box__3ZXz4 {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 20px;
}

.history-modal_data_box__3ZXz4::-webkit-scrollbar {
  width: 6px;
}
.history-modal_data_box__3ZXz4::-webkit-scrollbar-track {
  background: #ccc;
}
.history-modal_data_box__3ZXz4::-webkit-scrollbar-thumb {
  background: var(--text-primary-color);
  border: 1px solid var(--text-input-color);
  border-radius: 6px;
}

.order-history_content__anUWk {
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
  gap: 22px;
  width: 100%;
}

.order-history_main_row__3hkcD {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-history_info_box__3Q5pO {
  display: flex;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.order-history_btn__3VpKX {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;
  margin: auto 0;
  width: 20px;
  height: 15px;
  transition: var(--common-transition);
}

.order-history_btn__3VpKX:hover {
  opacity: .7;
}

.order-history_btn_cross__2qdNH {
  width: 15px;
  height: 15px;
}

.order-history_img__giPKj {
  width: 100%;
  height: 100%;
}

.order-history_done_img__33RLl {
  width: 20px;
  height: 18px;
}

.order-history_details_box__2rzoU {
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  text-align: left;
}

.order-history_goods_name___Q8KP {
  max-width: 268px;
}

.order-history_amount_box__1cgCd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.order-history_sub_row__2QweV {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
}

@media screen and (max-width: 950px) {
  .order-history_sub_row__2QweV {
    grid-gap: 24px;
    gap: 24px;
  }
}

@media screen and (max-width: 600px) {
  .order-history_info_box__3Q5pO {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {
  .order-history_main_row__3hkcD {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-history_sub_row__2QweV {
    align-self: flex-end;
    grid-gap: 32px;
    gap: 32px;
  }

  .order-history_details_box__2rzoU {
    padding-right: 0;
    grid-gap: 10px;
    gap: 10px;
  }

  .order-history_amount_box__1cgCd {
    min-width: 60px;
  }
}

.main-page_content__3gNTT {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1160px;
  margin: 0 auto;
  text-align: left;
}

.main-page_title__2tJqP {
  max-width: 711px;
}

.main-page_main_btn__1YKvC {
  width: 300px;
  height: 72px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.main-page_section_btn__30Mx8 {
  align-self: center;
  color: var(--text-black-color);
}

.main-page_mission_text__2fVqc {
  max-width: 729px;
}

.main-page_clarification_box__3Ejet {
  display: flex;
  grid-gap: 74px;
  gap: 74px;
}

.main-page_clarificationEl__1_2vB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-page_clarification_text__1_Rzs {
  margin-top: -20px;
}

.main-page_beer_img__kjzkM {
  position: absolute;
  top: 0;
  right: -350px;
  width: 804px;
  height: 924px;
  z-index: -1;
}

.main-page_slider_box__15m08 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main-page_slide__E167O {
  width: 100%;
  height: 456px;
  border-radius: var(--common-border-radius);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
}

.main-page_slide_cover__1VwhW {
  display: flex;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: var(--common-border-radius);
}

.main-page_slider_text__8wQXl {
  max-width: 462px;
  margin: auto;
  margin-left: 164px;
}

.main-page_novalty__2h5oH {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-page_novalty_goods_box__1GVr- {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 260px));
  grid-gap: 60px 40px;
  gap: 60px 40px;
  justify-content: center;
}

.main-page_map__1FcrK {
  border-radius: var(--common-border-radius);
  width: 560px;
  height: 397px;
}

.main-page_map_box__ukVtr {
  width: 100%;
}

.main-page_map_items_box__1LeXS {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}

.main-page_shops_info__2XM6v {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 16px;
  gap: 16px;
}

.main-page_shops_info_row__23Ghx {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main-page_promo_items_box__3xenq {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

.main-page_mob_promo_items_box__182ot {
  display: none;
}

.main-page_collab_items_box__1Id4c {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: 48px;
}

.main-page_mob_collab_items_box__1LWos {
  display: none;
}

.main-page_collab_item__1xr1a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-page_promo_box__2v6D- {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.main-page_collab_box__2xYKN {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.main-page_collab_text__3FSEX {
  max-width: 348px;
}

.main-page_collab_img__204Eu {
  border-radius: var(--common-border-radius);
  width: 100%;
  min-width: 300px;
  height: 323px;
  object-fit: cover;
}

.main-page_map_description__xfzDt {
  flex-grow: 1;
}

.main-page_phone__20gPQ {
  text-decoration: none;
}

@media screen and (max-width: 1140px) {
  .main-page_map__1FcrK {
    width: 460px;
  }
}

@media screen and (max-width: 1040px) {
  .main-page_beer_img__kjzkM {
    right: -400px;
  }

  .main-page_novalty_goods_box__1GVr- {
    grid-gap: 60px 20px;
    gap: 60px 20px;
  }

  .main-page_promo_items_box__3xenq {
    grid-gap: 20px;
    gap: 20px;
  }

  .main-page_collab_items_box__1Id4c {
    grid-gap: 20px;
    gap: 20px;
  }
}

@media screen and (max-width: 900px) {
  .main-page_map__1FcrK {
    width: 360px;
    height: 300px;
  }
}

@media screen and (max-width: 800px) {
  .main-page_beer_img__kjzkM {
    width: 614px;
    height: 702px;
    top: 60px;
    right: -400px;
  }

  .main-page_mission_box__3YoU1 {
    margin-top: 60px;
  }

  .main-page_clarification_box__3Ejet {
    grid-gap: 27px;
    gap: 27px;
  }
}

@media screen and (max-width: 750px) {
  .main-page_slide__E167O {
    height: 391px;
  }

  .main-page_slider_text__8wQXl {
    font-size: 36px;
    line-height: 130%;
  }

  .main-page_slider_text__8wQXl {
    margin: auto 60px;
  }

  .main-page_collab_img__204Eu {
    order: -1;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .main-page_subtitle__3W7a8 {
    font-size: 36px;
    line-height: 130%;
  }

  .main-page_map_items_box__1LeXS {
    flex-direction: column;
  }

  .main-page_map__1FcrK {
    width: 100%;
    height: 440px;
  }

  .main-page_map_items_box__1LeXS {
    grid-gap: 24px;
    gap: 24px;
  }
}

@media screen and (max-width: 639px) {
  .main-page_clarification_box__3Ejet {
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 55px;
  }

  .main-page_collab_items_box__1Id4c {
    flex-direction: column;
  }

  .main-page_beer_img__kjzkM {
    top: 740px;
    right: -145px;
    width: 414px;
    height: 420px;
    transform: matrix(0.97, 0.26, -0.26, 0.97, 0, 0);
  }
}

@media screen and (max-width: 600px) {
  .main-page_mission_box__3YoU1 {
    margin-top: 48px;
  }

  .main-page_beer_img__kjzkM {
    top: 720px;
  }

  .main-page_slider_box__15m08 {
    margin-bottom: 0;
  }
  .main-page_novalty_goods_box__1GVr- {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .main-page_main_btn__1YKvC {
    width: 100%;
  }

  .main-page_title__2tJqP {
    margin-top: 24px;
  }

  .main-page_main_btn__1YKvC {
    font-size: 18px;
    line-height: 120%;
    height: 67px;
  }

  .main-page_beer_img__kjzkM {
    top: 580px;
  }

  .main-page_slide__E167O {
    height: 260px;
  }

  .main-page_slider_text__8wQXl {
    font-size: 24px;
    line-height: 120%;
    margin: auto 40px;
  }

  .main-page_shops_info_row__23Ghx {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .main-page_shops_info__2XM6v {
    align-items: flex-start;
  }

  .main-page_phone__20gPQ {
    margin-top: 16px;
  }

  .main-page_map__1FcrK {
    height: 320px;
  }

  .main-page_collab_box__2xYKN {
    padding-bottom: 40px;
  }

  .main-page_mob_text__3goTc {
    font-size: 16px;
    line-height: 120%;
  }
}

@media screen and (max-width: 450px) {
  .main-page_beer_img__kjzkM {
    top: 640px;
  }

  .main-page_mob_promo_items_box__182ot {
    display: flex;
    margin-bottom: 22px;
  }

  .main-page_promo_items_box__3xenq {
    display: none;
  }

  .main-page_promo_box__2v6D- {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .main-page_slide__E167O {
    height: 180px;
  }

  .main-page_slider_text__8wQXl {
    font-size: 18px;
    margin: auto 20px;
  }

  .main-page_title__2tJqP {
    margin: 32px 0 16px;
  }

  .main-page_subtitle__3W7a8 {
    font-size: 24px;
  }

  .main-page_beer_img__kjzkM {
    display: none;
  }

  .main-page_clarification_box__3Ejet {
    margin-bottom: 24px;
  }

  .main-page_map__1FcrK {
    height: 290px;
  }

  .main-page_mob_collab_items_box__1LWos {
    display: block;
    margin-bottom: 16px;
  }

  .main-page_collab_items_box__1Id4c {
    display: none;
  }

  .main-page_collab_img__204Eu {
    order: 0;
  }
}

.goods-card_content__3FBmD {
  display: flex;
  align-items: center;
  height: 100%;
}

.goods-card_img__1LRIj {
  width: 158px;
  height: 157px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.goods-card_main_info__Z97ph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}

.goods-card_name_box__1BxsE {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.goods-card_description__2TUf0 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.goods-card_info__1pZWr {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.goods-card_amount__2GgHq {
  text-align: left;
}

.goods-card_name__1E2O5 {
  text-align: left;
}

.goods-card_price__2my_B {
  min-width: 100px;
  text-align: right;
}

.goods-card_cell__1iqjR {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 260px;
  min-width: 220px;
}

.goods-card_cell__1iqjR .goods-card_img__1LRIj {
  width: 100%;
  height: 307px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
  margin-right: 0;
}

.goods-card_cell__1iqjR .goods-card_button_box__2wtV- {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.goods-card_cell__1iqjR .goods-card_button__u68WE {
  width: 130px;
  height: 32px;
}

.goods-card_cell__1iqjR .goods-card_rating__1KCtW {
  position: absolute;
  top: 259px;
  right: 16px;
}

.goods-card_cell__1iqjR .goods-card_price__2my_B {
  text-align: left;
}

.goods-card_second_row__2Klyf {
  display: none;
}

.goods-card_navlink__1qJjx {
  text-decoration: none;
}

.goods-card_cell__1iqjR .goods-card_navlink__1qJjx {
  width: 100%;
}

.goods-card_volume__128fT {
  margin-top: auto;
}

.goods-card_modal__28p19 {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.goods-card_reg_form__1M43F {
  width: 486px;
}

@media screen and (max-width: 1000px) {
  .goods-card_cell__1iqjR {
    min-width: 220px;
  }

  .goods-card_cell__1iqjR .goods-card_img__1LRIj {
    height: 265px;
  }

  .goods-card_cell__1iqjR .goods-card_rating__1KCtW {
    top: 216px;
  }
}

@media screen and (max-width: 850px) {
  .goods-card_img__1LRIj {
    width: 140px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .goods-card_content__3FBmD {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .goods-card_second_row__2Klyf {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .goods-card_btn__1K68V {
    display: none;
  }

  .goods-card_article__2yNzg .goods-card_price__2my_B {
    display: none;
  }

  .goods-card_cell__1iqjR .goods-card_button_box__2wtV- {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .goods-card_cell__1iqjR .goods-card_volume__128fT {
    margin-bottom: 4px;
  }

  .goods-card_cell__1iqjR .goods-card_price__2my_B {
    margin-bottom: 10px;
  }
  
  .goods-card_reg_form__1M43F {
    width: 386px;
  }
}

@media screen and (max-width: 500px) {
  .goods-card_cell__1iqjR {
    width: 100%;
    min-width: 140px;
  }

  .goods-card_reg_form__1M43F {
    width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .goods-card_cell__1iqjR .goods-card_img__1LRIj {
    height: 200px;
  }

  .goods-card_cell__1iqjR .goods-card_rating__1KCtW {
    top: 150px;
  }

  .goods-card_reg_form__1M43F {
    width: 252px;
  }
}

@media screen and (max-width: 360px) {
  .goods-card_cell__1iqjR {
    width: 140px;
  }

  .goods-card_cell__1iqjR .goods-card_img__1LRIj {
    height: 169px;
  }

  .goods-card_cell__1iqjR .goods-card_rating__1KCtW {
    top: 120px;
  }
}

.promo-card_content__1p6w8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 360px;
  min-width: 300px;
}

.promo-card_img__2eCOQ {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.promo-card_figure__1nv9- {
  margin: 0;
}

.promo-card_figcaption__IZTnX {
  text-align: left;
}

@media screen and (max-width: 1040px) {
  .promo-card_img__2eCOQ {
    height: 276px;
  }
}

@media screen and (max-width: 450px) {
  .promo-card_content__1p6w8 {
    max-width: 100%;
  }
}

.select_select__GUQoB {
  width: 100%;
  padding: 16px;
  padding-right: 50px;
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
  border: 2px solid var(--text-select-color);
  background-color: var(--background);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/static/media/arrow-select.281f5732.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 16px);
  background-position-y: 50%;
  cursor: pointer;
}

@media screen and (max-width: 400px) {
  .select_select__GUQoB {
    word-wrap: break-word;
  }
}

.assortment-page_content__3FNLp {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.assortment-page_content_header___fvOh {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.assortment-page_select__jb4GA {
  width: 538px;
}

.assortment-page_sorting_box__JNx2y {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.assortment-page_btn__g7uGy {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: var(--common-transition);
}

.assortment-page_btn_filters__zGVi- {
  width: 130px;
  height: 32px;
}

.assortment-page_btn__g7uGy:hover {
  opacity: .7;
}

.assortment-page_img__2kTvu {
  width: 100%;
  height: 100%;
}

.assortment-page_main_content__3Pks2 {
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-gap: 40px;
  gap: 40px;
}

.assortment-page_main_filters__vXW9p {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.assortment-page_filters_box__3SHFz {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.assortment-page_goods_box__2j8kh {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.assortment-page_filters_box_row__1tCTp {
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
  width: 100%;
  margin-bottom: 60px;
}

.assortment-page_filters_box_cell__24cgf {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-gap: 60px 40px;
  gap: 60px 40px;
  width: 100%;
  margin-bottom: 60px;
}

.assortment-page_mob_filters__762SS {
  display: flex;
  flex-direction: column;
}

.assortment-page_mob_btns__1TaM5 {
  display: flex;
  flex-direction: column;
}

.assortment-page_mob_sorting_box__xpTHp {
  display: none;
}

.assortment-page_filter_btn__2QaZw {
  display: none;
}

.assortment-page_filter_btn_400px__1FONl {
  display: none;
}

.assortment-page_pagination__NmrkZ {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  margin-top: auto;
}

.assortment-page_pagination_num_btn_box__33lz- {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.assortment-page_pagination_btn__3fhLz {
  width: 133px;
  height: 32px;
}

.assortment-page_mob_pagination_btn__3dHDu {
  display: none;
}

@media screen and (max-width: 1150px) {
  .assortment-page_filters_box_cell__24cgf {
    grid-gap: 40px 20px;
    gap: 40px 20px;
  }
}

@media screen and (max-width: 1100px) {
  .assortment-page_filters_box_cell__24cgf {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1040px) {
  .assortment-page_main_content__3Pks2 {
    grid-gap: 20px;
    gap: 20px;
  }
}

@media screen and (max-width: 887px) {
  .assortment-page_filters_box_cell__24cgf {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .assortment-page_main_content__3Pks2 {
    grid-template-columns: 1fr;
    grid-gap: 40px;
    gap: 40px;
  }

  .assortment-page_mob_filters__762SS {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }

  .assortment-page_mob_filters_range__3i3D9 {
    margin-top: 32px;
  }

  .assortment-page_main_filters__vXW9p {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }

  .assortment-page_mob_btns__1TaM5 {
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 12px;
  }

  .assortment-page_btn_filters__zGVi- {
    margin: 0;
  }

  .assortment-page_content_header___fvOh {
    flex-direction: column;
    width: 100%;
  }

  .assortment-page_select__jb4GA {
    width: 100%;
  }

  .assortment-page_sorting_box__JNx2y {
    display: none;
  }

  .assortment-page_mob_sorting_box__xpTHp {
    display: flex;
    grid-gap: 16px;
    gap: 16px;
  }

  .assortment-page_title_row__ldWXz {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .assortment-page_first_col__YnrGb {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
  }

  .assortment-page_filter_btn__2QaZw {
    display: block;
  }

  .assortment-page_second_col__21NHn {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
  }
}

@media screen and (max-width: 720px) {
  .assortment-page_filters_box_cell__24cgf {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 580px) {
  .assortment-page_mob_pagination_btn__3dHDu {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  .assortment-page_pagination_btn__3fhLz {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .assortment-page_filter_title__1hmIn {
    display: none;
  }

  .assortment-page_title_row__ldWXz {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .assortment-page_mob_sorting_box__xpTHp {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 470px) {
  .assortment-page_main_filters__vXW9p {
    grid-template-columns: 1fr;
  }

  .assortment-page_mob_filters__762SS {
    grid-template-columns: 1fr;
    grid-gap: 18px;
    gap: 18px;
  }

  .assortment-page_main_filter__3szTM {
    margin: 0;
  }

  .assortment-page_main_filter__3szTM:last-child {
    margin-bottom: 20px;
  }

  .assortment-page_mob_btns__1TaM5 {
    justify-content: space-between;
  }

  .assortment-page_mob_sorting_box__xpTHp {
    display: none;
  }

  .assortment-page_filter_title__1hmIn {
    display: block;
    margin: 0;
  }

  .assortment-page_title_row__ldWXz {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .assortment-page_filter_btn_400px__1FONl {
    display: block;
  }
}

.select-sorting_select__3CCRf {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 12px;
  transition: var(--common-transition);
  outline: none;
  border: none;
  background-color: var(--background);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/static/media/arrow-sorting.3f7dfd54.svg);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  cursor: pointer;
}

.checkbox_content__1xV-6 {
  position: relative;
  width: 100%;
}

.checkbox_input__11tsf {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox_label__3SWV6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;

}
.checkbox_label__3SWV6::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid var(--colors-checkbox-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkbox_label_with_bgc__1mqkZ::after {
  background-color: #fff;
}

input:checked + .checkbox_label__3SWV6::after {
  border-color: var(--colors-checkbox);
  background-color: var(--colors-checkbox);
  background-image: url(/static/media/checkbox.cfad3b99.svg);
}

.filters-box_content__uo3gc {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}

.filters-box_btn__jLIrU {
  border: 1px solid var(--text-select-color);
  outline: none;
  cursor: pointer;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 11px;
  width: 100%;
}

.filters-box_filters_box__1anrD {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 6px;
}

.filters-box_open_more_btn__OYCCY {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: var(--common-transition);
  align-self: flex-end;
}

.filters-box_open_more_btn__OYCCY:hover {
  opacity: .7;
}

.filters-box_filters_box__1anrD::-webkit-scrollbar {
  width: 6px;
}
.filters-box_filters_box__1anrD::-webkit-scrollbar-track {
  background: var(--text-input-color);
}
.filters-box_filters_box__1anrD::-webkit-scrollbar-thumb {
  background: var(--text-primary-color);
  border: 1px solid var(--text-input-color);
  border-radius: 6px;
}

.input-range_content__2hOCn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-range_inputs_box__-Ztgq {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
}

.input-range_input_box__2DHVb {
  position: relative;
  width: 100%;
}

.input-range_input_label__2HlJU {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 20px;
  height: 22px;
}

.input-range_input__2lmDL {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
  width: calc(100% - 35px);
  height: 30px;
  background-color: var(--background);
  border: 1px solid var(--text-select-color);
  padding: 0 0 0 32px;
}

@media screen and (max-width: 887px) {
  .input-range_input_box__2DHVb {
    width: 101px;
  }
}

@media screen and (max-width: 850px) {
  .input-range_inputs_box__-Ztgq {
    width: 100%;
    justify-content: space-between;
  }
}

.pagination-button_button__iarVS {
  border: none;
  outline: none;
  transition: var(--common-transition);
  background: none;
  background-color: var(--text-primary-color);
  padding: 0;
  border-radius: 10px;
  width: 32px;
  height: 32px;
	text-align: center;
  opacity: .4;
}

.pagination-button_button__iarVS:hover:enabled {
  cursor: pointer;
  background-color: var(--colors-button-hover);
}

.pagination-button_button__iarVS:active:enabled {
  background-color: var(--colors-button-pressed);
}

.pagination-button_active__3rlij {
  opacity: 1;
}

.start-page_content__SvOUe {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--text-black-color);
}

.start-page_logo__1G81M {
  width: 771px;
}

.start-page_question_box__3KxJu {
  display: flex;
  flex-direction: column;
}

.start-page_btn_box__1yckI {
  display: flex;
}

.start-page_btn__1YsZJ {
  width: 162px;
  height: 56px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.start-page_alarm__BpbLc {
  max-width: 600px;
}

@media screen and (max-width: 800px) {
  .start-page_logo__1G81M {
    width: 480px;
  }
}

@media screen and (max-width: 500px) {
  .start-page_logo__1G81M {
    width: 340px;
  }
}

@media screen and (max-width: 500px) {
  .start-page_logo__1G81M {
    width: 300px;
  }

  .start-page_btn__1YsZJ {
    width: 140px;
  }

  .start-page_btn__1YsZJ:first-child {
    margin-right: 20px;
  }
}

.header_header__v9FKw {
  position: relative;
}

.header_content__2x66L {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  max-width: 1160px;
  margin: 0 auto;
  padding: 16px 40px 11px;
}

.header_first_row__3Se_J {
  border-bottom: 1px solid rgba(162, 157, 144, 0.5);
}

.header_location_box__2g_RZ {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.header_input__2cfQn {
  width: 300px;
}

.header_location__2Qddn {
  display: flex;
  align-items: center;
}

.header_second_row__3GT2A {
  border-bottom: 1px solid var(--text-select-color);
}

.header_logo__1X7aW {
  width: 257px;
  height: 64px;
  object-fit: cover;
}

.header_nav__2M5Zj {
  display: flex;
  align-items: center;
}

.header_link__3CRBA {
  text-decoration: none;
  transition: var(--common-transition);
}

.header_link__3CRBA:hover {
  opacity: 0.7;
}

.header_loc_btn__3OkS2 {
  cursor: pointer;
  display: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
}

.header_burger_icon__32wDo {
  cursor: pointer;
  display: none;
  padding: 0;
  margin: 0;
  margin-right: 24px;
  border: none;
  outline: none;
  background: none;
}

.header_loc_modal__q7vWN {
  top: 50px;
  right: 10px;
  padding: 20px;
}

.header_menu_modal__1yxK6 {
  padding: 10px;
}

.header_img__2KRuG {
  width: 24px;
  height: 32px;
}

.header_mob_icons__33GIe {
  display: none;
}

.header_f_mob_icons__2hY5j {
  display: flex;
  align-items: center;
}

.header_icon_img__3HRks {
  display: none;
}

.header_tooltip__1z2-r {
  position: relative;
  display: inline-block;
}

.header_tooltiptext__3k3XT {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: -40px;
}

.header_tooltip__1z2-r:hover .header_tooltiptext__3k3XT {
  visibility: visible;
}

.header_user_name__2rxeI {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header_tel__2uJ-0 {
  text-decoration: none;
  flex-shrink: 0;
}

.header_select__1apuF {
  padding-top: 4px;
  padding-bottom: 4px;
  max-width: 400px;
  width: 100%;
}

@media screen and (max-width: 1190px) {
  .header_user_name__2rxeI {
    display: none;
  }

  .header_icon_img__3HRks {
    display: block;
  }
}

@media screen and (max-width: 959px) {
  .header_content__2x66L {
    padding: 16px 10px 11px;
  }

  .header_law_text__2HkbS {
    padding: 10px;
  }
}

@media screen and (max-width: 920px) {
  .header_hidden_links__1NNoZ {
    display: none;
  }

  .header_burger_icon__32wDo {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .header_location__2Qddn {
    display: none;
  }

  .header_loc_btn__3OkS2 {
    display: block;
  }

  .header_location_box__2g_RZ {
    padding: 0;
  }

  .header_imgLocation__3c7A0 {
    width: 24px;
    height: 32px;
  }
}

@media screen and (max-width: 420px) {
  .header_burger_icon__32wDo {
    margin-right: 0;
  }

  .header_imgLocation__3c7A0 {
    width: 15px;
    height: 20px;
  }

  .header_f_mob_icons__2hY5j {
    display: none;
  }

  .header_mob_icons__33GIe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_menu_modal__1yxK6 {
    right: 12px;
  }
}

.footer_footer__1lZIc {
  border-top: 1px solid var(--text-select-color);
}

.footer_content__1hu0S {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1160px;
  padding: 31px 40px 32px;
  margin: 0 auto;
}

.footer_first_row__ITM8O {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 73px;
}

.footer_second_row__tnvVe {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 32px;
}

.footer_logo__2i4zh {
  width: 257px;
  height: 64px;
  object-fit: cover;
}

.footer_nav__lQCW9 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 142px 200px;
  grid-gap: 15px;
  gap: 15px;
  text-align: left;
}

.footer_socials__2f9Ii {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer_link__1Jh0W {
  text-decoration: none;
  transition: var(--common-transition);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.footer_link__1Jh0W:hover {
  opacity: 0.7;
}

.footer_tg_link__3ItFp {
  display: block;
  width: 32px;
  height: 32px;
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 10px;
}

.footer_img__1IVuV {
  margin-bottom: -5px;
}

.footer_tg_img__ELNOQ {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 959px) {
  .footer_content__1hu0S {
    padding: 31px 10px 32px;
  }
}

@media screen and (max-width: 650px) {
  .footer_content__1hu0S {
    padding: 31px 10px 38px;
  }

  .footer_first_row__ITM8O {
    flex-direction: column;
  }

  .footer_logo__2i4zh {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .footer_nav__lQCW9 {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }
}

.privacy-policy-page_content__1LwcW {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 40px;
  margin: 60px 0;
  background-color: var(--colors-interface-form);
  text-align: left;
  border-radius: var(--common-border-radius);
}

.privacy-policy-page_ol__jkiiv {
  color: #000;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.privacy-policy-page_li__mWN0P {
  margin-bottom: 20px;
}

.privacy-policy-page_li_title__1RYgX {
  margin-bottom: 10px;
}

@media screen and (max-width: 650px) {
  .privacy-policy-page_content__1LwcW {
    padding: 20px 20px;
    margin: 40px 0;
  }
}

.error-boundary_error_box__3DqpH {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.error-boundary_text__mlr7- {
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: var(--colors-slider-buttons);
  right: 56px;
  transition: var(--common-transition);
}

.swiper-button-prev {
  left: 56px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 0.7;
}

.swiper-button-next::after {
  content: url(/static/media/arrow-right.5984fc5e.svg);
  font-size: 30px;
}

.swiper-button-prev::after {
  content: url(/static/media/arrow-left.d8c59016.svg);
  font-size: 30px;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  opacity: 1;
  background: rgba(145, 142, 134, 0.4);
}

.swiper-pagination-bullet-active {
  background: var(--colors-slider-buttons);
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
}

.rc-slider-rail {
  background-color: #fff;
}

.rc-slider-track {
  background-color: var(--text-select-color);
}

.rc-slider-handle {
  border: 2px solid #2f1b10;
  background-color: #fff;
}

.PhoneInputCountry {
  display: none;
}

.PhoneInputInput {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--colors-border-default);

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.PhoneInputInput:focus {
  border: 1px solid var(--colors-checkbox-border);
}

/* .PhoneInputInput:valid {
  border: 1px solid var(--colors-border-correct);
} */

.PhoneInputInput:invalid {
  border: 1px solid var(--text-stock-color);
}

.PhoneInputInput:disabled {
  border: 1px solid var(--colors-checkbox-border);
}

@media screen and (max-width: 750px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .swiper .swiper-pagination {
    bottom: 0;
  }
}

