.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px 41px;
  margin-top: 32px;
  margin-bottom: 60px;
}

.info_box {
  text-align: left;
}

.text_box {
  padding-left: 30px;
}

.img {
  width: 100%;
  height: 413px;
  object-fit: cover;
  object-position: center top;
  border-radius: var(--common-border-radius);
}

.vertical_img {
  display: none;
  width: 100%;
  height: 526px;
  object-fit: cover;
  object-position: center top;
  border-radius: var(--common-border-radius);
}

@media screen and (max-width: 800px) {
  .content {
    grid-template-columns: 1fr;
    gap: 32px;
    margin-top: 24px;
  }

  .img {
    display: none;
  }

  .vertical_img {
    display: block;
    margin-bottom: 16px;
  }

  .title {
    margin-bottom: 16px;
  }

  .text {
    margin: 0 0 24px;
  }

  .text:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .vertical_img {
    height: 390px;
  }

  .content {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 400px) {
  .vertical_img {
    height: 254px;
  }
}
