.content {
  display: flex;
  align-items: center;
  height: 100%;
}

.img {
  width: 158px;
  height: 157px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.main_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}

.name_box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.description {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.amount {
  text-align: left;
}

.name {
  text-align: left;
}

.price {
  min-width: 100px;
  text-align: right;
}

.cell {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 260px;
  min-width: 220px;
}

.cell .img {
  width: 100%;
  height: 307px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
  margin-right: 0;
}

.cell .button_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cell .button {
  width: 130px;
  height: 32px;
}

.cell .rating {
  position: absolute;
  top: 259px;
  right: 16px;
}

.cell .price {
  text-align: left;
}

.second_row {
  display: none;
}

.navlink {
  text-decoration: none;
}

.cell .navlink {
  width: 100%;
}

.volume {
  margin-top: auto;
}

.modal {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.reg_form {
  width: 486px;
}

@media screen and (max-width: 1000px) {
  .cell {
    min-width: 220px;
  }

  .cell .img {
    height: 265px;
  }

  .cell .rating {
    top: 216px;
  }
}

@media screen and (max-width: 850px) {
  .img {
    width: 140px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .content {
    height: fit-content;
  }

  .second_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .btn {
    display: none;
  }

  .article .price {
    display: none;
  }

  .cell .button_box {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 4px;
  }

  .cell .volume {
    margin-bottom: 4px;
  }

  .cell .price {
    margin-bottom: 10px;
  }
  
  .reg_form {
    width: 386px;
  }
}

@media screen and (max-width: 500px) {
  .cell {
    width: 100%;
    min-width: 140px;
  }

  .reg_form {
    width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .cell .img {
    height: 200px;
  }

  .cell .rating {
    top: 150px;
  }

  .reg_form {
    width: 252px;
  }
}

@media screen and (max-width: 360px) {
  .cell {
    width: 140px;
  }

  .cell .img {
    height: 169px;
  }

  .cell .rating {
    top: 120px;
  }
}
