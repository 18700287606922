@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;500;700&display=swap');

@font-face {
  font-family: "SF Pro Display";
  src: url(./SFProDisplay-Semibold.woff) format("woff");
  font-weight: 600;
  font-display: swap;
}
