.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.data_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 20px;
}

.data_box::-webkit-scrollbar {
  width: 6px;
}
.data_box::-webkit-scrollbar-track {
  background: #ccc;
}
.data_box::-webkit-scrollbar-thumb {
  background: var(--text-primary-color);
  border: 1px solid var(--text-input-color);
  border-radius: 6px;
}
