.content {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

@media screen and (max-width: 600px) {
  .content {
    margin-bottom: 40px;
  }
}
