:root {
  --background: #1d1a1a;
  --background-alt: #1c1c1c;
  --offset-base-size: 2px;

  --common-border-radius: 20px;
  --text-primary-color: #ce9d60;
  --text-secondary-color: #e0bfa0;
  --text-additional-color: #cfbc9e;
  --text-white-color: #fff;
  --text-black-color: #1c1c1c;
  --text-red-color: #c83f3b;
  --text-select-color: #a29d90;
  --text-rating-color: #111;
  --text-input-color: #787878;
  --text-stock-color: #9f403d;
  --text-link-color: #88c2f9;
  --colors-interface-form: #f5f1e3;
  --colors-button-hover: #a86c21;
  --colors-button-pressed: #7e4600;
  --colors-checkbox: #5bb0ff;
  --colors-checkbox-border: #dddbcb;
  --colors-border-default: #dae2e8;
  --colors-border-correct: #0ab025;
  --colors-slider-buttons: #918e86;

  --scrollbar-color: var(--text-primary-color) var(--text-input-color);
  --scrollbar-width: thin;

  --common-transition: all 0.3s ease-in;
}

@-moz-document url-prefix() {
  :root {
    scrollbar-color: var(--scrollbar-color);
  }
}

body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: var(--text-input-color);
}
body::-webkit-scrollbar-thumb {
  background: var(--text-primary-color);
  border: 3px solid var(--text-input-color);
  border-radius: 6px;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  color: var(--text-primary-color);
  background: var(--background);
}

/* Typography classes */

.text {
  margin: 0;
  padding: 0;
}

.text_color_primary {
  color: var(--text-primary-color);
}

.text_color_secondary {
  color: var(--text-secondary-color);
}

.text_color_black {
  color: var(--text-black-color);
}

.text_color_white {
  color: var(--text-white-color);
}

.text_color_red {
  color: var(--text-red-color);
}

.text_color_select {
  color: var(--text-select-color);
}

.text_color_input {
  color: var(--text-input-color);
}

.text_color_rating {
  color: var(--text-rating-color);
}

.text_color_additional {
  color: var(--text-additional-color);
}

.text_color_checkbox {
  color: var(--colors-checkbox);
}

.text_color_stock {
  color: var(--text-stock-color);
}

.text_color_link {
  color: var(--text-link-color);
}

.text_type_h1 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 140%;
}

.text_type_h2 {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
}

.text_type_h3 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.text_type_cart {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}

.text_type_goods-count {
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
}

.text_type_large {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
}

.text_type_large-semibold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
}

.text_type_order {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 120%;
}

.text_type_medium {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.text_type_medium-600 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
}

.text_type_medium-bold {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.text_type_sorting {
  font-family: "Exo", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
}

.text_type_sorting-bold {
  font-family: "Exo", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

.text_type_small {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}

.text_type_btn-cart {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.text_type_points_number {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 102px;
  line-height: 140%;
  letter-spacing: 0.9px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 900px) {
  .text_type_points_number {
    font-size: 80px;
    line-height: 140%;
  }
}

@media screen and (max-width: 700px) {
  .text_type_h1 {
    line-height: 120%;
  }

  .text_type_h2 {
    font-size: 24px;
    line-height: 120%;
  }
}

@media screen and (max-width: 500px) {
  .text_type_h1 {
    font-size: 32px;
  }
}

@media screen and (max-width: 400px) {
  .text_type_points_number {
    font-size: 102px;
  }
}
