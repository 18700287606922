.button {
  border: none;
  outline: none;
  transition: var(--common-transition);
  background: none;
  padding: 0;
  border-radius: var(--common-border-radius);
  width: 240px;
  height: 44px;
	letter-spacing: -0.31px;
	text-align: center;
  font-weight: normal;
}

.button:disabled {
  opacity: .5;
}

.form {
  background-color: var(--text-primary-color);
  min-height: 44px;
}

.form:disabled {
  opacity: .4;
}

.form:hover:enabled {
  cursor: pointer;
  background-color: var(--colors-button-hover);
}

.form:active:enabled {
  background-color: var(--colors-button-pressed);
}


.cart {
  background-color: var(--text-white-color);
  color: var(--text-black-color);
}

.cart:hover:enabled {
  cursor: pointer;
  opacity: .7;
}
