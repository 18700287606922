.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main_content {
  display: flex;
}

.right_column {
  max-width: 57%;
}

.text_box {
  max-width: 444px;
  letter-spacing: -0.31px;
  text-align: left;
  margin-left: 30px;
  margin-right: -14px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.vertical_img {
  display: none;
  width: 100%;
  height: 581px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

@media screen and (max-width: 900px) {
  .main_content {
    flex-direction: column;
    width: 100%;
  }

  .img {
    display: none;
  }

  .vertical_img {
    display: block;
  }

  .text_box {
    max-width: 100%;
    margin-bottom: 32px;
    padding-top: 24px;
  }

  .text {
    width: calc(100% - 20px);
  }

  .left_column {
    margin-right: 0;
    width: 100%;
  }

  .form {
    max-width: 460px;
    margin: 32px auto 0;
  }
}

@media screen and (max-width: 500px) {
  .vertical_img {
    height: 430px;
  }
}

@media screen and (max-width: 400px) {
  .title {
    text-align: left;
  }

  .text_box {
    margin-bottom: 16px;
  }

  .vertical_img {
    height: 280px;
  }

  .form {
    margin-top: 24px;
  }

  .main_content {
    margin-bottom: 40px;
  }
}
