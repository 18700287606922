.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 360px;
  min-width: 300px;
}

.img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: var(--common-border-radius);
}

.figure {
  margin: 0;
}

.figcaption {
  text-align: left;
}

@media screen and (max-width: 1040px) {
  .img {
    height: 276px;
  }
}

@media screen and (max-width: 450px) {
  .content {
    max-width: 100%;
  }
}
