.footer {
  border-top: 1px solid var(--text-select-color);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1160px;
  padding: 31px 40px 32px;
  margin: 0 auto;
}

.first_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 73px;
}

.second_row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 32px;
}

.logo {
  width: 257px;
  height: 64px;
  object-fit: cover;
}

.nav {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 142px 200px;
  gap: 15px;
  text-align: left;
}

.socials {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
  transition: var(--common-transition);
  height: fit-content;
}

.link:hover {
  opacity: 0.7;
}

.tg_link {
  display: block;
  width: 32px;
  height: 32px;
  background-color: #fff;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 10px;
}

.img {
  margin-bottom: -5px;
}

.tg_img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 959px) {
  .content {
    padding: 31px 10px 32px;
  }
}

@media screen and (max-width: 650px) {
  .content {
    padding: 31px 10px 38px;
  }

  .first_row {
    flex-direction: column;
  }

  .logo {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 400px) {
  .nav {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
