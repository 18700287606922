.content {
  display: flex;
  flex-direction: column;
  min-width: 252px;
}

.cost_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.points_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.checkbox {
  margin-top: 26px;
}

.title {
  align-self: center;
}

.btn {
  align-self: center;
  margin-top: 32px;
}

.shop_text {
  text-align: center;
}

.modal {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.form_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 630px) {
  .content {
    min-width: 100%;
  }

  .title {
    align-self: flex-start;
    margin-bottom: 27px;
  }

  .points_box {
    margin-top: 20px;
  }

  .checkbox {
    margin-top: 18px;
  }

  .mob_input {
    margin-top: 16px;
  }

  .btn {
    margin-top: 24px;
  }
}
