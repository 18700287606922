.content {
  position: relative;
  width: 100%;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  user-select: none;
  cursor: pointer;

}
.label::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid var(--colors-checkbox-border);
  border-radius: 4px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
}

.label_with_bgc::after {
  background-color: #fff;
}

input:checked + .label::after {
  border-color: var(--colors-checkbox);
  background-color: var(--colors-checkbox);
  background-image: url(../../../images/icons/checkbox.svg);
}
