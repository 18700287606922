.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
}

.input_phone {
  width: 100%;
  height: 40px;
}

.label {
  text-align: start;
}

.form {
  width: calc(100% - 32px);
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--colors-border-default);
}

.form:focus {
  border: 1px solid var(--colors-checkbox-border);
}

/* .form:valid {
  border: 1px solid var(--colors-border-correct);
} */

.form:invalid {
  border: 1px solid var(--text-stock-color);
}

.form:disabled {
  border: 1px solid var(--colors-checkbox-border);
}

.search {
  position: relative;
  width: calc(100% - 70px);
  height: 30px;
  background-color: var(--background);
  border: 1px solid var(--text-select-color);
  padding: 0 34px;
  background: url(../../../images/icons/search.svg) no-repeat scroll 8px 6px;
}

.search::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: var(--text-select-color);
}

.eye_btn {
  position: absolute;
  top: 36px;
  right: 8px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: red;
  margin-top: 10px;
  text-align: left;
}
