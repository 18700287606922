.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputs_box {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
}

.input_box {
  position: relative;
  width: 100%;
}

.input_label {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 20px;
  height: 22px;
}

.input {
  transition: var(--common-transition);
  outline: none;
  border-radius: var(--common-border-radius);
  width: calc(100% - 35px);
  height: 30px;
  background-color: var(--background);
  border: 1px solid var(--text-select-color);
  padding: 0 0 0 32px;
}

@media screen and (max-width: 887px) {
  .input_box {
    width: 101px;
  }
}

@media screen and (max-width: 850px) {
  .inputs_box {
    width: 100%;
    justify-content: space-between;
  }
}
